export const sizes = {
    NAVBAR_HEIGHT_DESKTOP: '6.5rem',
    NAVBAR_HEIGHT_MOBILE: '9rem',

    HERO_BANNER_HEIGHT_DESKTOP: '560px',
    HERO_BANNER_HEIGHT_MOBILE: '740px',

    H1_FONT_SIZE_DESKTOP:'2.5rem',

    BORDER_RADIUS: '25px'
}

export const colors = {
    MAIN_YELLOW: '#D5992E',
    CONTRAST_BLUE: '#1A3F82',
    BACKGROUND_GREY: '#f8f8f8'
}

export const strings = {
    SCANYE_URL: "https://scanye.pl/"
}
